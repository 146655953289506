import * as React from 'react';

import Footer from '../../components/layout/footer';
import Link from '../../components/link/link';
import SEO from '../../components/seo/seo';

import './contact.css';

const Contact = () => (
  <>
    <SEO title="Contact" />
    <main className="contact">
      <div className="contact__inner">
        <h1>Contact</h1>
        <p>
          You can contact the authors at this email:
          {' '}
          <Link
            linkType="plain"
            to="mailto:contact@sockpickerbook.com"
          >
            contact@sockpickerbook.com
          </Link>
        </p>
      </div>
      <Footer links={['author', 'home', 'shop']} />
    </main>
  </>
);

export default Contact;
